.errorModal {
  width: 400px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 100000;
  background-color: aqua;
  border-radius: 10px;

  transform: translateX(-50%) scale(0.5);
  animation: addAnimation 0.5s forwards ease;
  @keyframes addAnimation {
    100% {
      transform: translateX(-50%) scale(1);
      opacity: 1;
    }
  }

  .errorModalBtnBox {
    display: flex;
    justify-content: flex-end;
  }

  .errorModalBtn {
    margin-right: 10px;
    background-color: blue;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-align: center;
  }
}
