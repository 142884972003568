.infoBlock {
  width: 100%;
  display: flex;
  border-radius: 10px;
  position: absolute;
  z-index: 10000;
  top: 325px;


  .infoBlockLeft {
    width: 50%;
    height: 230px;
    background-color: #32527b;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    color: #fff;
    text-align: center;

  }

  .infoBlockRight {
    width: 50%;
    height: 230px;
    background-color: red;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #43464B;
    color: #fff;
    text-align: center;
  }
}
