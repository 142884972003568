.searchingSectionBlock {
  width: 40%;
  height: 700px;
  display: flex;
  justify-content: center;

  .searchingSectionBlockContainer {
    width: 90%;
    height: 60%;
    background-color: #9f9eac3b;
    border-radius: 10px;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    .selectBoxWrapperFirst {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      width: 95%;
    }
    .selectBoxWrapperLast {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      width: 95%;
    }
    .applayButton{
      margin-top: 60px;
      width: 30%;
      border-radius: 5px;
      
     &:hover{
      background-color: silver;
      color: #fff;
     } 
    }
  }
}
