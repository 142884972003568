.main {
  height: 100vh;
  overflow-x: hidden;




  .loginPageLogoWrapper {
    width: 100%;
    height: 100px;
    background-color: #0f1233;
    display: flex;
    justify-content: center;
    align-items: center;

    .loginPageLogo {
      font-size: 30px;
      color: #fff;
      text-align: center;
      font-family: "Lato";
    }
  }



  .loginPageFooterWrapper {
    width: 100%;
    height: 80px;
    background-color: #0f1233;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100%;
    .loginPageFooter {
      font-size: 16px;
      color: silver;
      text-align: center;
      font-family: "Lato";
    }
  }

  .loginBlock {
    width: 100%;
    margin-top: 30px;
    margin-left: 34%;
    .loginContainer {
      width: 40%;
  
      .formBlock {
        background-color: #dddada38;
        border-radius: 8px;
        width: 80%;
        margin-top: 50px;
      }
      .heading {
        margin-bottom: 20px;
        text-align: center;
      }
    }
  
    .invalid {
      border: 1px solid red;
    }
  }
}


