.navigationBlock {
  width: 100%;
  height: 100px;
  background-color: #0f1233;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #fff;

  .navigationlogo {
    width: 30%;
    font-size: 30px;
    color: #fff;
    text-align: center;
    font-family: "Lato";
  }
  .navigationNav {
    width: 70%;
    display: flex; 
    justify-content: center;

    .navigationUl {
      width: 35%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 70px;
      padding: inherit;

      & li {
        list-style: none;
        cursor: pointer;
        color: white;
        font-size: 16px;
        font-family: "Lato";
        font-weight: bold;
      }
    }
  }
}
