.servicesBlock {
  width: 100%;
  height: 1350px;
  background-color: #222831;
  position: relative;

  .ulStyle {
    display: block;
    padding: 0;
    margin: 0 auto;
 
  }

  .liStyle {
    width: 33.3333%;
    display: block;
    float: left;
    height: 321px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .liStyle:nth-last-of-type(1) {
    border-right: none;
    border-bottom: none;
  }
  .liStyle:nth-last-of-type(4) {
    border-right: none;
  }
  .liStyle:nth-last-of-type(2) {
    border-bottom: none;
  }
  .liStyle:nth-last-of-type(3) {
    border-bottom: none;
  }

  .serviceTitle {
    color: #fff;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
  }

  .servicesFooterBlock {
    background-color: #1a1e25;
    height: 450px;

   
  }

  .serviceItemBlock {
    border: 1px solid rgba(255, 255, 255, 0.1);
     width: 45%;
     margin-right: 30px;
 

    .header {
      padding: 16px 20px;
      color: #fff;
      background-color: #32527b;
      font-size: 20px;
      font-weight: 700;
    }
    .body {
      margin-top: 2%;
      font-size: 14px;
      font-weight: 700;
      padding: 1px;
      color: #fff;
      text-align: center;
      height:  80px;
    }
  }
}
