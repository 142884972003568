.table {
  border: 1px solid black;
  width: 47%;
  margin-top: 30px;
  font-family: "Lato";

  .tableHeader {
    font-size: 11px;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    color: #fff;
    text-align: center;
    padding: 5px 15px;
  }

  .tableCol {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    color: black;
    font-weight: 700;
    width: 40%;
    height: 47px;
  }

  .tableColCorrection {
    border: none;
    width: 100%;
    height: 100%;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .tableColCor {
    text-align: center;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    font-weight: 700;
  }
  .btnEdit{
    background-color: rgba(0, 110, 255, 0.514);
    color: black;
  }
}
