.tableSectionBlock {
  width: 100%;
  padding: 10px;
  background-color: #9f9eac3b;
  margin-top: 30px;
  border-radius: 10px;
 


  .tableSectionBlockHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .tableSectionBlockHeaderContainer {
      width: 95%;
      display: flex;
      justify-content: space-between;

      & span {
        font-family: "Lato";
        font-size: 14px;
        color: #463d3dde;
      }
    }

    .btnWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .btn {
         margin-right: 20px;
         width: 20%;
      }
    }
  }
  .headerTitlelWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .headerTitlelContainer {
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .teamName {
        font-family: "Lato";
        font-weight: bold;
      }
    }
  }
  .statisticBtn {
    width: 12%;
    height: 30px;
    outline: none;
    border-radius: 15px;
    border: 3px solid red;
    background-color: #fff;
    color: red;
    font-weight: 700;
    font-family: "Lato";
    margin-left: 43%;
    cursor: pointer;
    background-image: url(../../assets/icon/statistics.svg);
    background-repeat: no-repeat;
    padding-left: 13px;
    &:hover{
      color: black;
      transition: 0.5s;
    }
  }
  .tableContainer {
    width: 100%;
    display: flex;
  }
}
