.table {
  border: 2px solid black;
  width: 100%;
  margin-top: 30px;
  font-family: "Lato";


  .tableHeader {
    font-size: 11px;
    padding: 5px 15px;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    color: #fff;
    text-align: center;
  
  }
  .tableRow {
    font-size: 10px;
    text-align: center;
    border-right: 1px solid black; 
    border-bottom: 1px solid black;
    color: black;
    font-weight: 700;
  }

  .tableCol{
    font-size: 10px;
    border-right: 1px solid black; 
    border-bottom: 1px solid black;
    color: black;
    font-weight: 700;

  }
}

