.headerBlock {
  height: 100vh;
  background-image: url(../../assets/images/bg_3.jpg);
  background-size: cover;
  position: relative;


  &::before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .headerBlockContainer {
    padding: 0 190px;

    .headerNavigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 80px;
      
      position: sticky;
      top: 0;


      .headerLogo {
        width: 30%;
        color: white;
        font-size: 30px;
        font-weight: 700;
        font-family: "Lato";
        z-index: 100;
      }
      .wrapper {
        z-index: 100;
        nav {
          width: 450px;
        }
        ul {
          padding-left: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        li {
          color: white;
          list-style: none;
          font-size: 13px;
          font-weight: 700;
          cursor: pointer;
          font-family: "Lato";
          text-decoration: none;
        }
      }
      .navigation-item {
        color: white;
        padding: 6px 15px;
        text-decoration: none;

     
        &:hover {
          color: white;
          border-bottom: 3px solid white;
        }
      }
    }
  }

  .header-text-content {
    width: 38%;
   margin-left: 70%;
 

    .title {
      
      font-weight: bold;
      font-size: 4rem;
      color: black;
      font-family: "Lato";
      
    }
    .text {
      color: black;
      font-size: 16px;
      font-weight: 300;
      font-family: "Lato";
      margin-top: 30px;
    }
  }
}
