.headerBlockContainer {
  padding: 0 190px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #43464b;
  z-index: 1000000;

  .headerNavigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;

    .headerLogo {
      width: 30%;
      color: white;
      font-size: 30px;
      font-weight: 700;
      font-family: "Lato";
      z-index: 100;
    }
    .wrapper {
      z-index: 100;
      nav {
        width: 450px;
      }
      ul {
        padding-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      li {
        color: white;
        list-style: none;
        font-size: 13px;
        font-weight: 700;
        cursor: pointer;
        font-family: "Lato";
        text-decoration: none;
      }
    }
    .navigationItem {
      color: white;
      padding: 6px 15px;
      text-decoration: none;

      &:hover {
        color: white;
        border-bottom: 3px solid white;
      }
    }
  }
}
