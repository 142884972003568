.modalBlock {
  width: 100%;
  position: absolute;
  top: 7%;
  left: 50%;
  display: flex;
  justify-content: space-evenly;
  background-color: silver;
  padding: 12px;
  flex-wrap: wrap;
  border: 1px solid lavenderblush;
  border-radius: 5px;

  transform: translateX(-50%) scale(0.5);
  animation: addAnimation 0.5s forwards ease;

  .closeIconBlock {
    width: 98%;
    display: flex;
    justify-content: flex-end;
  }

  .tableWrapper {
    display: flex;
    justify-content: space-between;
  }
  .headerTitlelContainer {
    display: flex;
    justify-content: space-evenly;
  }

  .teamName {
    width: 50%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
  .footerBtnBlockWrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }

  @keyframes addAnimation {
    100% {
      transform: translateX(-50%) scale(1);
      opacity: 1;
    }
  }
}
